import { Route, Switch, useRouteMatch } from "react-router-dom"
import './styles.css'
import BusyBeeePrivacy from './Privacy'
import BusyBeeeHome from './home'


export default function  BusyBeeIndex(){
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path='/busybeee' exact>
        <BusyBeeeHome></BusyBeeeHome>
      </Route>
      <Route path='/busybeee/privacy' exact>
        <BusyBeeePrivacy></BusyBeeePrivacy>
      </Route>
    </Switch>
  )
}
