import * as React from 'react'
import { Accordion } from 'react-bootstrap'
import MenuSectionDetails from './MenuSectionDetails'
import { FeedContext, Menu } from './types'


export type MenuDetailsInput  = {
  menu: Menu
  feedContext: FeedContext
}


const MenuDetails: React.FC<MenuDetailsInput> = ({menu, feedContext}) => {
  return (
    <>
      {menu?.menu_sections?.map((section, index) => (
      <Accordion key={index} flush className="menu_details_accordion">
        <Accordion.Item eventKey={index.toString()}>
          <MenuSectionDetails menuSection={section} feedContext={feedContext}></MenuSectionDetails>
        </Accordion.Item>
      </Accordion>
      ))}
    </>
  )

}

export default MenuDetails