import { api } from "../App";
import { User } from "./types";

export async function fetchCurrentUser() {
  try {
    const response = await api.get<User>("/inventory/portal/get_user")
    const user = response.data
    return user
  } catch (err) {
    return null
  }
}