import { Route, Switch, useRouteMatch } from "react-router-dom"
import Dashboard from "./Dashboard";
import './styles.css'

const PortalIndex: React.FC = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      {/* <Route path={`${match.path}/login`}>
        <PortalLogin></PortalLogin>
      </Route> */}
      <Route path={`${match.path}/dashboard`}>
        <Dashboard></Dashboard>
      </Route>
    </Switch>
  )
}

export default PortalIndex