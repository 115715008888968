import { Dropdown } from "react-bootstrap"
import { StoreFeed } from "./types"

type MenuSelectorInput = {
  feed: StoreFeed | null
  selected_menu: string | null
  select_menu: (eventKey: string | null, e: React.SyntheticEvent<unknown>) => void
}

const MenuSelector: React.FC<MenuSelectorInput> = (input) => {
  
  function isCurrentMenu(menu_id: string) {
    return menu_id === input.selected_menu
  }

  const current_menus = input?.feed?.menus?.filter(
    m => { return isCurrentMenu(m.menu.id) }
  )
  const current_menu = current_menus && current_menus.length > 0 ? current_menus[0] : null

  return (
    <Dropdown onSelect={input.select_menu} className="btn-primary-outline btn-block menu_selector_dropdown">
      <Dropdown.Toggle variant="outline-secondary" className="btn-block">
        {current_menu?.menu.name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        { input?.feed?.menus?.map(m => {
            let className = "dropdown-item-spacer"
            if (m.menu.id === current_menu?.menu.id) {
              className += " dropdown-item-checked"
            }
            return <Dropdown.Item key={m.menu.id} eventKey={m.menu.id} className={className}>{m.menu.name}</Dropdown.Item>
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MenuSelector