import * as React from 'react'
import { Container, Row } from 'react-bootstrap'
import { api } from '../App'
import { Menu, StoreFeed } from './types'
import './styles.css'
import MenuDetails from './MenuDetails'
import { useLocation, useParams } from 'react-router-dom'
import MenuSelector from './MenuSelector'
import ItemDetails from './item/ItemDetails'


const StoreMenuPage: React.FC = () => {
  let { storeId } = useParams<{storeId: string}>()
  const searchParams = new URLSearchParams(useLocation().search)
  const features = {
    app_ordering_enabled: searchParams.get('app_ordering_enabled') === "true"
  }

  const [feed, setFeed] = React.useState<StoreFeed | null>(null)
  const [menuId, setMenuId] = React.useState<string | null>(null)
  const [menu, setMenu] = React.useState<Menu | null>(null)
  const [showItemDetails, setShowItemDetails] = React.useState<boolean>(false)
  const [itemId, setItemId] = React.useState<string | null>(null)

  // default page title
  React.useEffect(() => {
    document.title = "Lazycat online menu";  
  }, []);

  React.useEffect(() => {
    async function fetchStoreFeed(storeId: string) {
      const storeFeedResponse = await api.get<StoreFeed>(
        '/feed/get_store_feed',
        { params: {store_id: storeId}}
      )
      const storeFeed = storeFeedResponse.data
      setFeed(storeFeed)
      console.log(storeFeed)
    
      const menuId = storeFeed.default_menu
      console.log("Default menu id", menuId)
      setMenuId(menuId)

      // Update page title
      document.title = storeFeed.store.name + " - online menu"
    }
    console.log("Loading store feed!")
    fetchStoreFeed(storeId)
  }, [storeId])

  React.useEffect(() => {
    async function fetchMenu(menuId: string) {
      const menuResponse = await api.get<Menu>(
        '/feed/get_menu',
        { params: { menu_id: menuId }}
      )
      const menu = menuResponse.data
      setMenu(menu)
    }
    console.log("Loading store menu!")
    if (menuId) {
      fetchMenu(menuId)
    }
  }, [menuId])

  function handleItemDetailOpen(itemId: string) {
    setItemId(itemId)
    setShowItemDetails(true)
  }

  function handleItemDetailClose() {
    setItemId(null)
    setShowItemDetails(false)
  }

  if (menu) {
    return (
      <div className="store-menu-page-container">
        <Container className="full_width_container">
          <Row>
            <div className="menu-selector-container">
              <MenuSelector
                feed={feed}
                selected_menu={menu?.id}
                select_menu={(newMenuId) => setMenuId(newMenuId)}>
              </MenuSelector>
            </div>
          </Row>
        </Container>
        <MenuDetails menu={menu} feedContext={{
          features: features,
          menuId: menuId!!,
          storeId: storeId!!,
          itemDetailsCallback: handleItemDetailOpen
          }}>
        </MenuDetails>

        <ItemDetails itemId={itemId} show={showItemDetails} features={features} hideItemDetails={handleItemDetailClose}></ItemDetails>
      </div>
    )
  } else {
    return (<></>)
  }
}

export default StoreMenuPage