import './App.css'
import axios from 'axios'
import { Component } from 'react'
import StoreMenuPage from './menu/StoreMenuPage'
import BusyBeeIndex from './busybeee/index'
import PortalIndex from './portal/index'
import {
  Switch,
  Route,
  BrowserRouter,
} from 'react-router-dom'
import { PortalUrlPrefix } from './portal/Constants'
import { Helmet} from 'react-helmet'


export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL
//    baseURL: "http://localhost:8000/api/lazycat"
})


class App extends Component {


  render() {

    return (
      <>
        <Helmet>
          <title>Lazy cat online menu</title>
        </Helmet>
        <BrowserRouter>
          <Switch>
            <Route path='/busybeee'>
              <BusyBeeIndex></BusyBeeIndex>
            </Route>
            <Route path='/store/:storeId'>
              <StoreMenuPage></StoreMenuPage>
            </Route>
            <Route path='portal'>
              <PortalIndex></PortalIndex>
            </Route>
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}



  
// }

export default App;
