import { Accordion } from 'react-bootstrap'
import ItemSection from "./ItemSection"
import { FeedContext, MenuSection } from "./types"

export type MenuSectionDetailsInput = {
  menuSection: MenuSection
  feedContext: FeedContext
}

const MenuSectionDetails: React.FC<MenuSectionDetailsInput> = ({menuSection, feedContext}) => {
  const lastIndex = menuSection.items.length - 1
  return (
    <>
      <Accordion.Header>
        <div>
          <div className="menu_section_title">{menuSection.name}</div>
          {menuSection.availabilities.map((availability, index) => (
            <div className="menu_section_subtitle">Available from: {availability.display}</div>
          ))}
        </div>
      </Accordion.Header>
      <Accordion.Body>
      {menuSection.items.map((item, index) => (
        <ItemSection key={item.id} item={item} feedContext={feedContext} isLastItem={index === lastIndex}></ItemSection>
      ))}
      </Accordion.Body>
    </>
  )

} 

export default MenuSectionDetails