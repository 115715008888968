import * as React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { FaMinus, FaPlus } from 'react-icons/fa';
import './item.css'

export type QuantitySelectorInput = {
  quantity: number
  onQuantityChange: (newQuantity: number) => void
}


const QuantitySelector: React.FC<QuantitySelectorInput> = ({quantity, onQuantityChange}) => {

  function incrQuantity() {
    onQuantityChange(quantity + 1)
  }

  function decrQuantity() {
    if (quantity > 1) {
      onQuantityChange(Math.max(quantity - 1, 1))
    }
  }
  
  function setQuantity(value: string) {
    let newQuantity = parseInt(value)
    if (isNaN(newQuantity)) {
      newQuantity = 1
    }
    onQuantityChange(newQuantity)
  }

  return (
    <>
    <Row className="quantity-selector-container">
      <Col>
        <Button variant='outline-secondary' className="float-right" onClick={decrQuantity}>
          <FaMinus/>
        </Button>
      </Col>
      <Col>
        <div className="quantity-selector-text-control">
          <Form.Control type="text" value={quantity} onChange={e => setQuantity(e.target.value)} className="quantity-selector-text-control-input"/>
        </div>
      </Col>
      
      <Col>
        <Button variant='outline-secondary' className="float-left" onClick={incrQuantity}>
          <FaPlus/>
        </Button>
      </Col>
    </Row>
      
    </>
  )
}

export default QuantitySelector