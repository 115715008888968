import * as React from 'react'
import './styles.css'


const BusyBeeeHome: React.FC = () => {
    return <div className="base_container">
        <h2>Busy Beee Home</h2>

        <h4>Introduction</h4>
        <p>Busy Beee is a mobile app that informs users the best cash back available at each merchant</p>

        <h4>How to use the app</h4>
        <p>First navigate to the manage cards view, there you can add credit cards that you own. Note this is different
        from apple wallet, you are not entering your credit card number or any details, the app only need to know which credit
        card you have. Once you are done adding cards, you can then navigate to the explore page where you can tap on merchants
        shown in the map to view a list of credit cards that give good cash backs. If the merchant you are looking for is not
        shown on the map, you can tap on the search bar to bring up the search page. You can search merchants by name and address.
        You can also search by cash back category. For example, if you simply want to know which card is best for filling up
        your gas tank, you can search for "Gas" and tap on the shown cash back category, and it will display the best cards
        to use at gas stations.</p>

        <h4>Contact Us</h4>
        <p>Busy Beee is developeed by Frankie Liu, and you can send any app inquiries to this email address:
        <a href = "mailto:lazycat.devops@gmail.com?subject=Feedback">lazycat.devops@gmail.com</a>
        </p>

        <h4>Privacy Terms</h4>
        <p>View privacy terms <a href="/busybeee/privacy">here</a>
        </p>
    </div>
}

export default BusyBeeeHome