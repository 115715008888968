import { Checkbox } from "@material-ui/core"
import { Row } from "react-bootstrap"
import { ItemExtra } from "../types"

export type ItemOptionDetailsInput = {
  extras: ItemExtra[]
}

const ItemOptionDetails: React.FC<ItemOptionDetailsInput> = ({extras}) => {
  if (extras.length === 0) {
    return <></>
  }
  return (
    <>
      <hr/>
      { extras.map((extra) => {
        return <>
          <Row>
            <div>{extra.name}</div>
          </Row>
          {extra.options.map((option) => {
            return <Row>
              <div>
                <Checkbox disabled inputProps={{ 'aria-label': 'disabled checkbox' }} className="item-option-details-checkbox" size="small"/>
                <div className="item-option-details-option-name">{option.name}</div>
              </div>
            </Row>
          })}
        </>
      })}
    </>
  )
}

export default ItemOptionDetails