import * as React from 'react'
import { FeedContext, MenuItem } from './types'
import { Col, Row } from "react-bootstrap"
import './styles.css'

export type ItemSectionInput = {
  item: MenuItem
  feedContext: FeedContext
  isLastItem: boolean
}

const ItemSection: React.FC<ItemSectionInput> = ({item, feedContext, isLastItem}) => {

  function openItemDetails() {
    feedContext.itemDetailsCallback(item.id)
  }
  return (
  <>
    <Row className={isLastItem ? "item_section_div_last" : "item_section_div"} onClick={openItemDetails}>
      <Col>
          <Row>
            <div className="item_section_name">{item.name}</div>
          </Row>
          { item.description ? (
            <Row className="item_section_description">
              <div>{item.description}</div>
            </Row>
          ) : (<></>)}
          <Row className="item_section_price">
            <div>{item.display_price}</div>
          </Row>
      </Col>
      {item.image_url ? (
        <Col xs={5} sm={5} md={4} lg={3}>
          <div className="item_section_image_container">
            <img className="item_section_image" src={item.image_url} alt="item"></img>
          </div>
        </Col>) : (<></>)}
    </Row>
  </> 
  )
}

export default ItemSection