import * as React from 'react'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { api } from '../../App'
import { formatCurrencyAmount } from '../../common/utils'
import { Features, Item } from '../types'
import ItemOptionDetails from './ItemOptionDetails'
import QuantitySelector from './quantitySelector'

export type ItemDetailsInput = {
  itemId: string | null
  show: boolean
  features: Features
  hideItemDetails: () => void
}


const ItemDetails: React.FC<ItemDetailsInput> = ({itemId, show, features, hideItemDetails}) => {
  const [item, setItem] = React.useState<Item | null>()
  const [quantity, setQuantity] = React.useState<number>(1)

  React.useEffect(() => {
    async function fetchItemDetails(itemId: string) {
      const itemDetailsResponse = await api.get<Item>(
        '/feed/get_item',
        { params: {item_id: itemId} }
      )
      const itemDetails = itemDetailsResponse.data
      setItem(itemDetails)
    }

    if (itemId) {
      fetchItemDetails(itemId)
      setQuantity(1)
    } else {
      setItem(null)
    }
  }, [itemId])

  function hideAndResetItemDetails() {
    hideItemDetails()
  }

  function onQuantityChange(newQuantity: number) {
    const verifiedQuantity = Math.max(Math.min(newQuantity, 999), 1)
    setQuantity(verifiedQuantity)
  }

  function calculatePrice() {
    if (item == null) {
      return ""
    }
    const totalPrice = quantity * item.price
    return formatCurrencyAmount(totalPrice)
  }

  return (
    <>
    <Modal show={show} fullscreen='lg-down' onHide={hideAndResetItemDetails}>
      <div className="item-details-modal">
        <Modal.Header closeButton>
          <Modal.Title className="item-details-name">{item?.name}</Modal.Title>
        </Modal.Header>
      </div>
      
      <Modal.Body>
        <div className="item-details-modal">
          { item?.image_url ? (
            <Row>
              <div>
                <img src={item.image_url} className="item-details-image" alt="item"></img>
              </div>
          </Row>
          ) : (<></>)}
          <Row>
            <div className="item-details-description">{item?.description}</div>
          </Row>
          <ItemOptionDetails extras={item ? item.extras : []}></ItemOptionDetails>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="item-details-modal">
          {features.app_ordering_enabled &&
            <Row>
            <Col><QuantitySelector quantity={quantity} onQuantityChange={onQuantityChange}></QuantitySelector></Col>
            <Col md="auto" sm="auto" lg="auto"><Button variant="primary">
              Add to Cart - {calculatePrice()}
            </Button></Col>
            </Row>
          }
          {!features.app_ordering_enabled &&
            <Button variant="secondary" onClick={hideAndResetItemDetails}>
              Close
            </Button>
          }            
        </div>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default ItemDetails