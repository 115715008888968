import * as React from 'react'
import { Button, Container, Modal } from "react-bootstrap"
import { useHistory } from 'react-router'
import { LoginAbsUrl } from './Constants'
import { fetchCurrentUser } from './Sessions'
import { User } from './types'


const Dashboard: React.FC = () => {
  const history = useHistory()
  const [_, setUser] = React.useState<User>()
  const [showLogin, setShowLogin] = React.useState(false)

  function redirectToLogin() {
    history.push(LoginAbsUrl)
  }

  React.useEffect(() => {
    fetchCurrentUser().then(user => {
      if (user) {
        setUser(user)
      } else {
        setShowLogin(true)
      }
    }).catch(err => {
      setShowLogin(true)
    })
  })

  return (
    <>
      <Container fluid>
        <p>hello</p>

      </Container>
      <Modal show={showLogin} onHide={redirectToLogin}>
        <Modal.Header closeButton>
          <Modal.Title>You are not logged in, please login first</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={redirectToLogin}>OK</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Dashboard